
// Theme Color 
$primary:       #3f165a !default; // Chatdit color
//$primary:       #248fb9 !default;  // Socialsross color
$secondary:     #333b44 !default;
$success:       #60c31f !default;
$info:          #2678d2 !default;
$warning:       #ff9d04 !default;
$danger:        #db1c24 !default;
$dark:          #0b2645 !default;
$white:         #fff !default;
$gray-100:      #f8f9fa !default;
$gray-200:      #e9ecef !default;
$gray-300:      #d9d9d9 !default;
$gray-400:      #2c2c2b !default;
$gray-500:      #adb5bd !default;
$gray-600:      #6c757d !default;
$gray-700:      #495057 !default;
$gray-800:      #343a40 !default;
$gray-900:      #212529 !default;
$cccccc:        #cccccc !default;


//
$turquoise-blue:#71ebb2 !default;
$pink:          #f63869 !default;
$instagram:     #d93175 !default;
$google:     	#dc473a !default;
$facebook:      #3b5998 !default;
$youtube:       #fe0000 !default;
$twitter:       #55acee !default;
$pinterest-p:   #cb2027 !default;
$behance:       #4176fa !default;
$dribbble:      #f26798 !default;
$linkedin:      #007ab9 !default;

// Theme Customize
$blue:          #01579B !default;
$purple:        #6A1B9A !default;
$green:         #1B5E20 !default;
$black:         #000 !default;
$orange:        #BF360C !default;
$teal:          #004D40 !default;
$cyan:          #006064 !default;
$gray3:         #455A64 !default;
$red:           #B71C1C !default;


// Margin 
$box-margin:     30px;


$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $gray-200,
    "dark":       $dark,
    "white":      $white,
    "black":      $black,
);


