// Angular Material Common Changes
a {
    &:hover, &:focus {text-decoration: none;}
}
.pull-right {float: right !important;}
.mat-form-field {width: 100%;}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {padding: 0px;}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {color: $primary;}
.mat-form-field.mat-focused .mat-form-field-label {color: $primary;}
.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {background-color: $primary;}
.mat-fab.mat-success, .mat-flat-success.mat-success, .mat-mini-fab.mat-success, .mat-raised-button.mat-success {background-color: $success;}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $primary;}
//textarea.mat-input-element {min-height: 70px;}
.tootip-class {background-color: #000; color: #fff; line-height: 17px;}
//.mat-form-field-appearance-outline .mat-form-field-infix {padding-top: 7px;}

.header-dropdown {
    .mat-menu-item {line-height: 34px; height: 34px;
        &:focus {outline: none;}
    }
    @media(max-width: 767px) {position: absolute; right: 20px;}
}
.loader {position: absolute; left: 0px; border-radius: 0%; width: 100%; height: 100%; display: flex; top: 0px; align-items: center; justify-content: center; background-color: rgba(0, 0, 0, 0.7);
    span { position: absolute; display: inline-block; width: 50px; height: 50px; border-radius: 100%; background:rgba(135, 211, 124,1); -webkit-animation: loader3 1.5s linear infinite; animation: loader3 1.5s linear infinite;
        &:last-child { animation-delay:-0.9s; -webkit-animation-delay:-0.9s;}
    }
 }
 @keyframes loader3 {
    0% {transform: scale(0, 0);opacity:0.8;}
    100% {transform: scale(1, 1);opacity:0;}
 }
 @-webkit-keyframes loader3 {
    0% {-webkit-transform: scale(0, 0);opacity:0.8;}
    100% {-webkit-transform: scale(1, 1);opacity:0;}
 }
 .custom-table {
	td, th {border-color: $gray-300; color: $gray-600; vertical-align: middle; padding: 0.85rem 0.65rem;}
	thead {
		th {color: $gray-600; background-color: #f0eeef; border-bottom: none; font-weight: 600;}
		a {color: $gray-600;}
		a:hover {color: darken($gray-600, 6.5);}
	}
	thead, tbody {
		tr td:last-child {text-align: center;}
	}
} // Custom table css end

// Bootstrap Components common changes
/*.dropdown-menu {opacity: 0; border: 0; transform: scale(0) !important; transform-origin: 0 0; will-change: transform, opacity; transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); box-shadow: 0 2px 2px 0 rgba($black, 0.14), 0 3px 1px -2px rgba($black, 0.2), 0 1px 5px 0 rgba($black, 0.12); top: 100% !important; left: 0px !important;
	.dropdown-item {position: relative; display: flex; flex-flow: row wrap; align-items: center; min-width: 7rem; max-width: 17.5rem; min-height: 2.2rem; padding: 0.4rem 1rem; overflow: hidden; line-height: 1; text-overflow: ellipsis; word-wrap: break-word; color: inherit;
	    &:hover {text-decoration: none; color: $primary;}
        &.active {color: $primary;}
    }
    &.show {opacity: 1; transform: scale(1) !important;}
} */
.card {border-radius: 0px; border-color: $cccccc;}
.card-body {padding: 14px;}
.card-header {padding: 0.65rem 14px;}

.custom-dialog {
    button:focus {outline: none;}
    .mat-form-field {font-weight: 500;}
    .mat-dialog-container {padding: 0px; border-radius: 0px;}
    .header {padding: 20px;
        h3 {margin-bottom: 0px; }
        .mat-button {padding: 0px 5px; min-width: 10px; font-size: 18px;}
    }
    .mat-dialog-content {margin: 0px; padding-top: 30px; max-height: 85vh;}
    .mat-dialog-actions {margin-bottom: 6px; padding: 8px 25px;}
} // Custom Dialog css end

.announce-dialog {position: absolute !important; right: 0px; top: 77px;
    button:focus {outline: none;}
    .mat-form-field {font-weight: 500;}
    .mat-dialog-container {padding: 0px; border-radius: 0px;}
    .header {padding: 20px;
        h3 {margin-bottom: 0px; }
        .mat-button {padding: 0px 5px; min-width: 10px; font-size: 18px;}
    }
    .mat-dialog-content {margin: 0px; padding-top: 30px; max-height: 85vh;}
    .mat-dialog-actions {margin-bottom: 6px; padding: 8px 25px;}
}

.privacy-dialog {
    button:focus {outline: none;}
    .mat-form-field {font-weight: 500;}
    .mat-dialog-container {padding: 0px; border-radius: 0px;}
    .header {padding: 20px;
        h3 {margin-bottom: 0px; }
        .mat-button {padding: 0px 5px; min-width: 10px; font-size: 18px;}
    }
    .mat-dialog-content {margin: 0px; padding-top: 30px; max-height: 455px; min-height: 455px;}
    .mat-dialog-actions {margin-bottom: 6px; padding: 8px 25px;}
    @media(max-width: 767px) {
        .mat-dialog-actions button {padding-left: 25px !important; padding-right: 25px !important;}
        .privacy-scroll {min-height: calc(100vh - 200px) !important;}
        .mat-dialog-content {max-height: calc(100vh - 120px) !important; min-height: calc(100vh - 110px) !important; padding: 0px !important;}
        .friends-privacy {padding: 10px 10px 0px 10px;}
        .friends-privacy .mat-checkbox {padding-left: 8px;}
    }
} // Privacy Dialog css end

.embed-dialog {
    button:focus {outline: none;}
    .mat-dialog-container {padding: 0px; border-radius: 0px;}
    .mat-dialog-content {padding: 0px; margin: 0px; overflow: hidden;}
    .priview {
        .view {width: calc(100% - 285px); float: left;
            iframe {width: 100%; margin-bottom: -7px;}
        }
        .caption {width: 285px;
            .header {padding: 10px; }
            .links {width: 100%; float: left; padding: 15px;
                pre {width: 100%; float: left; overflow: hidden; white-space: inherit;}
            }
        }
    }
}

// Custom Components
.custom-card {margin-bottom: $box-margin;
    .card-title {text-align: center; border-bottom: 1px solid $cccccc; font-size: 17px; font-weight: bold; padding-bottom: 12px; margin-bottom: 15px; text-transform: uppercase;}
} // Custom Card css end

.card.invites {
    h4 {font-size: 16px; font-weight: bold; }
    p {font-size: 14px;}
    label {font-size: 16px; font-weight: bold;}
    .mat-flat-button {padding: 6px 8px;}
}

.ad-box {margin-bottom: $box-margin;
    video {width: 100%;}
    iframe {width: 100%;}
    img {width: 100%;}
} // Ad box css end

ul.explore-menu {list-style: none; line-height: 36px;
    > li {
        > a {color: $gray-400; transition: all 0.3s ease-in-out; font-size: 14px; font-weight: 600;
            .fa {width: 24px; color: $primary; font-size: 16px;}
            &:hover, &.active {text-decoration: none; color: $primary;}
        }
    }
} // Explore menu css end

ul.trends-list {list-style: none;
    li {
        h6 {font-size: 15px; font-weight: 800; color: $gray-400; margin-bottom: 2px;
            a {color: inherit; }
            a:hover {text-decoration: none; color: $primary;}
        }
        p {font-size: 13px; font-weight: 600; line-height: 22px; color: $gray-400;}
    }
} // Trends list css end

/*ul.suggestion-list {
    li {padding: 0.75rem;
        &:first-child {padding-top: 0px; border-top: none;}
        .image {width: 26px; margin-right: 5px; float: left;}
        .caption {width: calc(100% - 36px); float: left;
            h6 {margin-bottom: 0px; font-size: 14px; font-weight: 600; color: $gray-400; margin-bottom: 0px; flex-basis: 0; flex-grow: 1; max-width: 100%; padding-right: 5px;
                a {color: inherit; }
                a:hover {text-decoration: none; color: $primary;}
            }
            .button .btn {font-size: 13px; font-weight: 600;}
        }
    }
}*/ // Friends Suggestion list css end

ul.suggestion-list {
    li {padding: 5px;
        &:first-child {padding-top: 0px; border-top: none;}
        .image {width: 31px; margin-right: 5px; float: left;}
        .caption {width: calc(100% - 36px); float: left;
            h6 {margin-bottom: 0px; font-size: 15px; font-weight: 600; color: lighten($gray-400, 9%); margin-bottom: 0px; flex-basis: 0; flex-grow: 1; max-width: 100%;
                a {color: inherit; }
                a:hover {text-decoration: none; color: $primary;}
            }
            .button {width: 85px; text-align: right;
                .btn {font-size: 11px; font-weight: 700; padding: 0.25rem 0.2rem;
                    + .btn {margin-top: 0.2rem;}
                    .fa {color: $primary; margin-right: 4px;}
                    &:hover .fa {color: $white;}
                }
            }
        }
    }
} // Page Like list css end

.profile-card {margin-bottom: $box-margin;
    .card-header {height: 60px; background-color: $primary; width: calc(100% + 2px); margin-left: -1px; margin-right: -1px; margin-top: -1px; border-radius: 0px;}
    .profile {position: relative;
        .image {margin-top: -50px; width: 75px; margin-right: 10px;}
        .heading {width: calc(100% - 85px); margin-top: -5px;
            h6 {font-size: 15px; font-weight: 800; color: $gray-400; margin-bottom: 2px;
                a {color: inherit; }
                a:hover {text-decoration: none; color: $primary;}
            }
            p {font-size: 13px; font-weight: 600; line-height: 22px; color: $gray-400;}
        }
    }
    ul {list-style: none; padding: 0px; text-align: center; margin-bottom: 0px; margin-right: -4px; margin-left: -4px; font-size: 14px; line-height: 26px; font-weight: 600;
        li {padding-left: 4px; padding-right: 4px; width: 100%;
            a {width: 100%; text-align: center; color: $gray-400;}
            a:hover {text-decoration: none; color: $primary;}
        }
    }
    &.absolute-pic {
        .card-header {height: 50px;}
        .upload {position: absolute; right: 20px; bottom: 10px; z-index: 9; overflow: hidden;
            .btn {position: relative; padding: 2px 7px; font-size: 14px; margin: 0px;}
            input[type="file"], input[type="button"] {position: absolute; left: 0px; top: 0px; width: 35px; opacity: 0; cursor: pointer;}
        }
        .profile {
            .image {position: absolute; top: -70px; left: 50%; margin-left: -50px; width: 100px;
                img {border: 4px solid $white; width: 100%;}
                h6 {position: absolute; top: 25px; left: 100%; white-space: nowrap; color: $white; font-weight: 800; padding-left: 15px;}
            }
            .heading  {width: 100%;}
            .heading h6 {font-size: 18px; margin-bottom: 8px;}
        }
    }
    @media(max-width: 991px) {margin-bottom: 15px;}
    @media(max-width: 574px) {margin-bottom: 15px;
        &.absolute-pic {margin-bottom: 0px;
            .profile {
                .image {top: -30px;
                    h6 {display: none;}
                }
                .heading {margin-top: 15px;}
            }
        }
    }
} // Profile card css end

.page-card {
    .mat-form-field-appearance-outline .mat-form-field-infix {font-weight: 500;}
    .card-header {background-color: transparent; padding: 0.65rem 25px;
        .card-title {margin: 0px; font-size: 20px; font-weight: bold;}
        a:hover, a:focus {text-decoration: none;}
        .btn {
            + .btn {margin-left: 10px;}
            &:focus {text-decoration: none; outline: none;}
            .fa-plus-circle {margin-left: 4px;}
        }
        .mat-stroked-button + .mat-stroked-button {margin-left: 10px;}
        .input-group {max-width: 220px;
            .form-control {padding-right: 35px; border-color: $gray-300; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem;}
            .mat-stroked-button {min-width: 4px; position: absolute; top: 2px; right: 0px; box-shadow: none; border: none; z-index: 9; padding: 0px 10px;}
        }
    }
    .card-body {padding: 1rem 25px;}
    .btn-lg {font-size: 14px; font-weight: bold; text-transform: uppercase; padding: 0.9rem 1rem;}
    .btn-link {font-weight: 700; font-size: 14px;
        &:hover {text-decoration: none; }
    }
} // Page card css end

.timeline-menu {background-color: $white; margin-left: -30px;
    .nav-link {border-left: 1px solid $cccccc; font-weight: 700; color: lighten($black, 40%); padding: 12px 6px;
        &:hover {color: $primary;}
        &.active {background-color: #f3e6fc; color: $primary;}
    }
    .nav {border-bottom: 1px solid $cccccc;}
    .mat-tab-label {min-width: 94px; opacity: .9; border-right: 1px solid darken($gray-300, 8%);
        a {padding: 0 5px; position: relative; color: $gray-400; font-size: 15px;
            &.active {background-color: rgba(197,202,233,.3);
                &:before {content: ""; width: 100%; height: 2px; background-color: $primary; left: 0px; bottom: 0px; position: absolute; }
            }
        }
    }
    .mat-tab-labels {
        .mat-tab-label:last-child {border-right: none;}
    }
    .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {display: none;}
    @media(max-width: 991px) {margin-left: 0px; border-right: 1px solid $cccccc; border-left: 1px solid $cccccc; margin-bottom: 15px; float: left; width: 100%;
        .mat-tab-label {min-width: 80px;}
        .mat-tab-group {margin-bottom: 0px !important;}
    }
    @media(max-width: 767px) {
        .nav-link {padding: 6px 4px;}
        .mat-tab-label {min-width: 40px;}
    }
} // Timeline menu css end

.new-post {margin-bottom: $box-margin;
    .comment.mat-form-field-appearance-outline .mat-form-field-infix {padding: .3em 0 .3em 0;}
    .card-title {font-size: 16px; font-weight: bold; color: $gray-400;}
    textarea.mat-input-element {height: 70px;}
    .select-file {
        .custom-file {height: calc(1.5em + 0.75rem + 14px);}
        .custom-file-label {border-color: rgba($black, .12); height: calc(1.9em + 0.75rem + 9px);  padding: 0.775rem 0.75rem;
            &:after {height: calc(2.3em + 0.75rem); padding: 0.775rem 0.75rem;}
        }
    }
    .button {
        ul {list-style: none; padding: 0px; margin: 0px;
            li {float: left; position: relative;
                + li::before {content: "|"; padding: 0px 4px; position: absolute; top: -2px; left: -5px; color: $primary;}
                button, label {display: flex; font-size: 12px; font-weight: 700; color: $primary; border: none; align-items: center; background-color: transparent; padding: 0px 4px; cursor: pointer;
                    .fa {margin-right: 5px; color: darken($gray-300, 15%); font-size: 15px;}
                    .custom-file-input {position: absolute; pointer-events: none;}
                    &:focus {outline: none;}
                }
            }
        }
        .mat-form-field {width: 90px; margin: 0px 3px; height: 33px; line-height: 20px;}
        .mat-form-field-appearance-outline .mat-form-field-infix {padding: 3px 0px 6px 0px; margin-top: -5px;}
        .mat-form-field-appearance-outline .mat-select-arrow-wrapper {transform: translateY(0%);}
        .mat-form-field-appearance-outline .mat-form-field-wrapper {margin: 0px; padding: 0px;}
        .mat-form-field-appearance-outline .mat-form-field-flex {padding: 0 .45em 0 .45em;}
        .mat-select {font-size: 14px;}
        .mat-checkbox-layout {margin: 0px;}
        .btn-post {text-transform: uppercase; font-size: 12px; font-weight: 700; padding: 5px 15px;}
    }
    @media(max-width: 767px) {
        .mat-form-field-wrapper {padding-bottom: 0px;}
    }
    @media(max-width: 499px) {
        .emoji-mart {right: auto !important; left: -16px; width: 305px !important;

        }
    }
} // New post css end

.user-banner {position: relative;
	.upload {position: absolute; left: 30px; top: 30px; z-index: 9;
		.btn {position: relative; padding: 0.235rem 0.55rem;}
		input[type="file"], input[type="button"] {position: absolute; left: 0px; top: 0px; width: 35px; opacity: 0; cursor: pointer;}
	}
    .info {position: absolute; right: 30px; bottom: 30px;}
    .image {width: 100%; float: left; height: 100%;}
    .button {position: absolute; right: 15px; bottom: 15px;
        a {background-color: $white;}
        a.mat-primary {background-color: $primary; color: $white;}
    }
    @media(max-width: 574px) {
        .info {right: 4px; bottom: 4px;}
    }
} // User banner image css end

.timeline-post {margin-bottom: $box-margin;
    a:hover, a:focus {outline: none}
    .dropdown-menu {z-index: 10;}
    .card-header {background-color: transparent; padding: 8px;
        .dropdown-menu {left: auto !important; right: 0px; border-radius: 0px;
            .dropdown-item {border-radius: 0px; text-align: left; line-height: 18px; padding: 4px 12px; min-width: 34px; color: $primary;
                &:focus {color: $white; }
            }
        }
        .profile {width: 40px; float: left; margin-right: 10px;}
        .caption {width: calc(100% - 50px); float: left;}
        .handing {
            h5 {margin-bottom: 0px; font-size: 16px; font-weight: 700; white-space: inherit;
                a:hover {text-decoration: none;}
                span {color: $gray-400; font-size: 13px;}
            }
            .pin-post{transform: rotate(45deg); transform-origin: 20% 40%; margin-left: 1rem;}
        }
        .time {
            time {font-size: 13px; color: darken($gray-300, 20); }
            .btn-link {padding: 0px 8px; font-size: 16px; line-height: 28px;
                &:focus {text-decoration: none; outline: none;}
            }
        }
        .button {
            .btn-link {padding-right: 0px; padding-top: 0px; font-size: 16px;
                &:after {content: none;}
                .fa {transform: scale(1.2);}
                &:focus {text-decoration: none; outline: none;}
            }
        }
    } // Header css
    .group-image {width: 100%;
        video {width: 100%; height: 100%; max-height: 350px;}
        .videos {position: relative;
            &:before {content: ""; position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; /*background: rgba($black, 0.4);*/}
            .fas {position: absolute; color: $white; top: 15px; right: 15px;}
        }
        .open-image {cursor: pointer;}
        .w-50 {position: relative; 
            img-lazy {height: 222px;}
            figure {height: 222px;
                img {height: 100%;}
            }
        }
        .img-count-1 .w-50 {width: 100% !important;}
        .img-count-3 .w-50:first-child {width: 100% !important;}
        .hide {display: none;}
        .more-images {position: absolute; width: 100%; height: calc(100% - 7px); top: 0px; left: 0px; color: $white; font-size: 40px; background: rgba($black, .4); pointer-events: none;}
    } // Group images css
    .card-text {font-size: 14px; color: lighten($black, 7%); line-height: 28px; font-weight: 600;}
    .like-comment-count {font-weight: 600; font-size: 13px; color: darken($gray-300, 25);
        .fa {margin-right: 6px; transform: scale(1.3); color: $primary;}
    } // Like Comment count
    .reaction {padding: 5px; display: block; border-radius: 10px; max-width: 500px; display: flex; bottom: 25px; top: auto; left: -10px; z-index: 9; opacity: 0; transform: scale(0); transform-origin: 0 0; will-change: transform, opacity; transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1); box-shadow: 0px 0px 4px rgba($black, 0.2); pointer-events: none;
        li {padding: 3px; float: left;
            a {cursor: pointer;}
            img {width: 26px;}
        }
    }
    .comment-share {
        .count {position: relative;
            &:hover .reaction {opacity: 1; transform: scale(1); pointer-events: all;}
        }
        .btn {padding: 0px 5px; font-size: 13px; font-weight: 600; color: darken($gray-300, 25);
            .fa {margin-right: 6px; transform: scale(1.3); color: $primary;}
            &:hover {text-decoration: none; color: $primary;}
            &:focus {text-decoration: none; outline: none;}
        }
    } // Comment share button css
    .comments {
        textarea.mat-input-element {height: 70px;}
        .mat-form-field-appearance-outline .mat-form-field-infix {padding: .3em 0 .3em 0;}
        .mat-form-field-wrapper {padding: 0px; margin: 0px;}
        .profile {width: 40px; float: left;}
        .caption {width: calc(100% - 40px); float: left;}
    } // Comment form css
    .comment-list {
        .group {margin-bottom: 12px;
            .profile {width: 40px; float: left;}
            .caption {width: calc(100% - 40px); float: left;
                h6 {font-size: 16px; font-weight: bold; margin-bottom: 2px; padding-right: 15px;
                    a:hover {text-decoration: none;}
                    .action {position: absolute; right: 1px; top: 0px; opacity: 0; transition: all 0.3s ease-in-out;
                        .dropdown-menu {padding: 0px; margin: 0px; min-width: 6rem; border-radius: 0px; right: 0px; transform: none !important; left: auto !important; top: 100% !important;
                            .dropdown-item {border-radius: 0px; text-align: left; line-height: 30px; padding: 0 12px; min-width: 34px; color: $primary;
                                &:focus {color: $white; }
                            }
                        }
                    }
                    .dropdown-toggle {font-size: 12px; cursor: pointer;
                        &:after {content: none;}
                    }
                }
                .text {font-size: 13px; font-weight: 600; line-height: 20px; color: lighten($gray-400, 15%);}
            }
        }
        ul.list {list-style: none; margin: 0px; padding: 0px;
            .bg-list {background-color: rgba($primary, 0.08); margin: 0px 0px 2px; padding: 7px 5px 5px;
                &:hover .action {opacity: 1;}
            }
            > div > li {
                &:last-child hr {display: none}
                //ul {margin: 0px; padding: 0px 0px 0px 25px; list-style: none;}
            }
            ul.list {padding-left: 5px;}
        }
        .likes {margin-bottom: 14px; position: relative;
            .btn {padding-top: 0px; padding-bottom: 0px; font-size: 13px; font-weight: 600;
                b {color: $primary; font-weight: 700;}
                .fa {margin-right: 6px; transform: scale(1.2); color: $primary;}
                &:hover {text-decoration: none; color: $primary;}
                &:focus {text-decoration: none; outline: none;}
            }
            time {padding-left: 20px; font-size: 13px; color: lighten($gray-400, 15%);}

        }
        .comments {margin-bottom: 14px;
            textarea.mat-input-element {height: 50px;}
        }
        .reply-comments {padding: 0px 0px 0px 25px;
            ul.list {
                .bg-list {padding: 6px 5px 1px; margin-bottom: 3px;}
                .group {margin-bottom: 8px;
                    .profile {width: 30px;}
                    .caption {
                        h6 {font-size: 13px; margin-bottom: 1px;}
                        .text {font-size: 12px;
                            .emoji {width: 18px; height: 18px;}
                        }
                    }
                }
                .likes {margin-bottom: 6px;}
            }
        }
    } // Comments list css
    .view-more {
        .btn-link {padding: 0px; font-size: 13px; font-weight: 600; text-decoration: underline; color: $gray-400; cursor: pointer;
            &:after {content: none;}
            .fa {transform: scale(1.2);}
            &:focus {text-decoration: none; outline: none;}
        }
    }
    .post-video-priview {border: 1px solid #ddd; float: left; height: 250px; position: relative; margin: 5px -15px -15px -15px; width: calc(100% + 30px);
        iframe {left: 0px; top: 0px; height: 100%;}
    }
    @media(max-width: 767px) {
        hr {margin: 10px 0px;}
        .comments .caption {width: calc(100% - 70px);}
        .profile {width: 30px;}
        .like-comment-count {padding: 5px 10px;}
        .comment-list .comments textarea.mat-input-element {height: 35px; min-height: 35px;}
        .comment-list .likes {margin-bottom: 5px;
            .btn {padding-left: 4px; padding-right: 4px; font-size: 11px;}
            time {padding-left: 2px; font-size: 10px;}
        }
    }

} // Timeline post css end

.about-list {
    + .about-list {margin-top: 15px; border-top: 1px solid #ddd; padding-top: 15px;}
    h4 {font-size: 16px; font-weight: 900; margin-bottom: 2px;}
    .mat-button {padding: 0px 8px; min-width: 10px; font-size: 18px; color: $primary; }
    .text {
        h6 {font-size: 16px; font-weight: bold;}
    }
} // About list css end

.friend-box {margin-bottom: 30px; border: 1px solid $gray-300; border-radius: 4px;
    .image {
        img {border-top-left-radius: 4px; border-bottom-left-radius: 4px;}
    }
    .caption {padding: 8px;
        h4 {font-size: 14px; font-weight: bold; margin-bottom: 0px; flex-basis: 0; flex-grow: 1; max-width: 100%; line-height: 20px; word-break: break-word;
            a {color: inherit;}
            a:hover, a:focus {text-decoration: none; outline: none;}
        }
        .button {
            a {padding: 0 9px; min-width: 47px;
                + a {margin-left: 6px;}
                &:hover, &:focus {text-decoration: none;}
            }
            .mat-flat-button {font-size: 14px;}
        }
    }
    @media(max-width: 574px) {margin-bottom: 15px;
        .caption {flex-wrap: wrap !important; padding: 0px 8px;
            .button {width: 100%;
                a, button {padding: 0 6px; min-width: 30px; line-height: 26px;}
            }
        }
    }
} // Friends box css end

.group-banner {
    a:hover, a:focus {text-decoration: none;}
    .mat-stroked-button {border-radius: 0px; color: $gray-400;
        .fas, .far {color: $primary; margin-right: 4px;}
    }
}  // Group Banner css end

.pages-box {
    .image {text-align: center;}
    .caption {padding: 10px;
        h4 {font-size: 16px; font-weight: bold;}
        .like {font-size: 14px; font-weight: 600; color: $gray-400; margin-bottom: 10px;}
    }
    .mat-stroked-button, .mat-flat-button {min-width: 35px;}
} // Pages box css end

.event-box {border-bottom: 1px solid $gray-300;
    .card-header {background-color: transparent; border-bottom-color: transparent;
        .dropdown-menu {left: auto !important; right: 0px;}
        .profile {width: 40px; float: left; margin-right: 10px;}
        .caption {width: calc(100% - 50px); float: left;}
        .handing {
            h5 {margin-bottom: 0px; font-size: 16px; font-weight: 700;
                a:hover {text-decoration: none;}
                span {color: $gray-400; }
            }
        }
        .time {
            time {font-size: 13px; color: darken($gray-300, 20); }
            .btn-link {padding: 0px 8px; font-size: 16px; line-height: 28px;
                &:focus {text-decoration: none; outline: none;}
            }
        }
        .button {
            .btn-link {padding-right: 0px; padding-top: 0px; font-size: 16px;
                &:after {content: none;}
                .fa {transform: scale(1.2);}
                &:focus {text-decoration: none; outline: none;}
            }
        }
    } // Header css
    .caption2 {padding: 0rem 25px 0.65rem;
        .address {font-size: 13px; color: #a19d9d; margin-bottom: 5px;
            .fas {color: $primary; margin-right: 3px;}
        }
        .text {font-size: 14px; font-weight: 500;}
    }
    .footer {padding: 0.65rem 25px;
        .mat-stroked-button {font-size: 13px; font-weight: 600; margin-right: 12px; border-color: transparent;
            &:focus {box-shadow: none;}
            .far {color: $primary; transform: scale(1.2); margin-right: 4px;}
        }
    }
} // Event box css end

.photo-box {position: relative;
    .image {position: relative;
        a {cursor: pointer;}
        .delete {position: absolute; top: 3px; right: 3px; color: $white; cursor: pointer;
            &:hover {color: $white;}
        }
        
    }
    .caption {position: absolute; bottom: 7px; left: 0; width: 100%; color: $white; opacity: 0; transition: all .2s ease-in-out; box-shadow: inset 0 -105px 74px 33px rgba($black,.58); }
    .comment-over {color: $white;
        [class*="border"] {border-color: $white !important;}
        h6 {font-size: 16px; font-weight: bold; padding: 5px; margin-bottom: 0px;
            a {color: $white;}
            a:hover {text-decoration: none;}
        }
        .mat-stroked-button {font-size: 12px; line-height: 24px; padding: 0px 5px; width: 100%; text-align: left;
            .far, .fas {margin-right: 3px;}
            &:hover {color: $white;}
        }
    }
    &:hover .caption {opacity: 1;}
} // Photo box css end

.photo-row {margin-left: -8px; margin-right: -8px;
    [class*="col"] {padding-left: 8px; padding-right: 8px;}
} // Photos row css end

.upload-priview {
    .banner {position: relative; width: 160px;
        input {position: absolute; left: 0px; width: 160px; height: 160px; opacity: 0;  cursor: pointer;}
        .image-error {display: none;}
        .ng-invalid ~ .image-error {display: block;}
    }
    .img-view {margin-left: 15px; width: 160px; height: 160px;}
} // Upload priview

.spinner-size {width: 40px !important; height: 40px !important;
    svg {width: 40px !important; height: 40px !important;}
    &.mat-primary circle {stroke: $primary;}
} // Spinner size css end

.post-upload {display: flex; flex-wrap: wrap;
    .img-ul-file-upload {width: 160px; height: 160px; background: $white url("/assets/img/upload-bg.jpg") no-repeat center center; background-size: cover; position: relative; display: flex; align-items: center; margin-right: 10px;
        .img-ul-button {margin: 0px; text-align: center; color: $dark; font-weight: bold; box-shadow: none; font-size: 1.75em; line-height: 36px; text-indent: -9999px; opacity: 0; height: 130px; width: 100%;}
    }
    .img-ul-container {padding: 0px !important; display: flex; align-items: center;
        .img-ul-image {padding: 0 10px; border: 1px solid $gray-300; width: 140px;}
    }
}  // Post Image upload

.news-box {background-color: $white; border: 1px solid $gray-300;
    .image {width: 100%;}
    .caption {padding: 10px 20px;
        a {color: $dark;
            &:hover {color: $primary;}
        }
        h6 {padding-bottom: 6px; text-decoration: underline; font-size: 15px;}
        h4 {font-size: 18px; font-weight: 600; }
    }
} // News Box css end

.blog-box {background-color: $white; border: 1px solid $gray-300;
    .image {width: 100%;}
    .caption {padding: 10px 20px;
        h3 {font-size: 19px; font-weight: bold; padding-bottom: 4px;
            a {color: $dark;
                &:hover {color: $primary;}
            }
        }
        time {font-size: 13px; color: $gray-400; margin-bottom: 1px;}
        .text {margin-bottom: 10px; font-weight: 600; font-size: 14px; }
        .action {width: -100%;

        }
    }
} // Blog box css end
.blog-popular {
    .card-header {text-align: center; font-weight: bold; font-size: 16px; text-transform: uppercase; margin-bottom: 15px; border-bottom: none; }
    ul {padding: 0px; margin: 0px; list-style: none;
        li {margin-bottom: 30px; padding-bottom: 25px; position: relative;
            &:last-child {border-bottom: none !important; padding-bottom: 0px;}
            &:first-child {
                .image {max-width: 100%; width: 100%;
                    &:after {content: ""; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background-color: rgba($black, 0.4);}
                }
                .caption {position: absolute; display: flex; flex-wrap: wrap; align-items: center; justify-content: center; height: calc(100% - 70px); top: 20px; left: 20px; width: calc(100% - 40px); border: 5px solid $white; z-index: 9; padding: 15px;
                    h6 {color: $white;}
                    h4 {color: $white; text-align: center;}
                }
            }
            .image {position: relative; max-width: 200px;
                .count {color: $white; background-color: $primary; width: 30px; height: 30px; font-size: 13px; font-weight: bold; border-radius: 50%; position: absolute; top: -15px; left: 15px; z-index: 6;}
            }
            .caption {padding: 0px 15px;
                h6 {font-size: 14px; color: $gray-400; margin-bottom: 6px;}
                h4 {font-size: 18px; color: $dark; font-weight: bold;
                    a {color: inherit;}
                    a:hover {color: $primary; text-decoration: none;}
                }
            }
            @media(max-width: 574px) {margin-bottom: 15px;}
        }
    }
} // Blog Popular css end

.dropdown-notification {
    .scrolldiv .request {max-height: 220px; overflow-x: hidden; overflow-y: auto;}
    .dropdown-toggle:after {content: none;}
    .dropdown-menu {min-width: 360px; margin-top: 8px; border-radius: 2px; border: none; box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12); left: auto !important; right: 0px;
        .dropdown-arrow {position: absolute; bottom:100%; left: auto; right: -8px; overflow: hidden; height:22px; width:50px;
            &:after {content:''; transform: rotate(45deg); background: $white; position: absolute; top: 16px; right: 15px; width: 25px;height: 25px; border-radius: .25rem; }
        }
        .dropdown-header {border-bottom: 1px solid rgba(0,0,0,.1); padding: 0px 0px 6px; line-height: 22px;
            .font-18 {font-size: 18px;}
        }
    }
    .timeline {position: relative; padding: 0; list-style: none; padding-left: 10px;
        &:before {position: absolute; display: block; content: ''; width: 1px; height: 100%; top: 0; bottom: 0; left: 10px; background-color: #ebedf2;}
        &.scroller {max-height: 200px; min-height: 200px;}
        .scroll {height: 100%; overflow: auto;}
        .timeline-item {position: relative; padding: 6px 15px 6px 30px; display: flex; justify-content: space-between; align-items: center; background-color: $white;     font-size: 14px; line-height: 19px;
            a {color: inherit; width: 100%; }
            a:hover {text-decoration: none; }
            .timeline-icon {position: absolute; height: 20px; width: 20px; left: 6px; text-align: center; line-height: 20px;
                // top: 50%; margin-top: -10px;
                 font-size: 16px; background-color: transparent;}
        }
    }
    .request {position: relative; padding: 0; list-style: none; padding-left: 0px;
        .friend-box {margin: 0px; border-left: none; border-right: none; border-top: none; border-radius: 0px;
            .image img {height: 55px;}
            .button a {font-size: 12px; line-height: 28px; padding: 0 6px; min-width: 45px;}
        }
    }
    @media(max-width: 419px) {
        .dropdown-menu {min-width: 320px; right: -60px;
            .dropdown-arrow {right: 49px;}
        }
        app-notification {
            .dropdown-menu {right: -112px;
                .dropdown-arrow {right: 95px;}
            }
        }
    }
} // Notification dropdown css end

.mat-tab-label .mat-tab-label-content {width: 100%;}
.mat-tab-label {padding: 0px !important;
    a {padding: 0 24px; height: 48px; display: flex; align-items: center; justify-content: center; width: 100%; }
} // Custom Css end



.popup-dialog {height: calc(100% - 0px); width: 100% !important; max-width: 100% !important;
    .mat-dialog-container {border-radius: 0px; padding: 0px; overflow: hidden; background-color: transparent;}
    app-image-popup {width: 100%; position: relative; height: 100%; float: left;}
    @media(max-width: 767px) {
        .mat-dialog-container {overflow: scroll;}
        app-image-popup {height: 100vh;

        }
    }
} // Custom Dialog css end


.images-box {width: 100%; height: 100%; float: left;
    .image {width: calc(100% - 380px); float: left; height: 100%; position: relative; background-color: rgba($black, 0.8);
        &:before {content: ""; position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; transition: all 0.3s ease-in-out;}
        .btn {position: absolute; top: 48%; color: $white; font-size: 20px; opacity: 0; transition: all 0.3s ease-in-out;
            i {transform: scale(1.5);}
        }
        video {width: 100%;}
        .btn-prev {left: 15px;}
        .btn-next {right: 15px;}
        .btn-close {top: 15px; right: 15px;}
        &:hover {
            &:before {background-color: rgba($black, 0.3);}
            .btn {opacity: 1;}
        }
    }
    .comment {width: 380px; height: 100%; float: left; background-color: $white;
        .timeline-post {margin-bottom: 0px; height: 100%; padding-bottom: 80px;}
        .comment-list {height: 100%; overflow-y: auto; overflow-x: hidden;
            &::-webkit-scrollbar {width: 10px;} /* Track */
            &::-webkit-scrollbar-track {background: #f1f1f1; } /* Handle */
            &::-webkit-scrollbar-thumb {background: #888;}   /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {background: #555;}        }
        app-post-comment-form {position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid $gray-300; padding-top: 4px; background-color: $white;
            form {
                .mat-form-field-appearance-outline .mat-form-field-wrapper {padding-bottom: 0px; margin-bottom: 0px;}
                .profile {width: 40px;}
                .caption {width: calc(100% - 40px); width: calc(100% - 45px); padding-bottom: 7px;}
            }
        }
    }
    @media(max-width: 767px) {flex-wrap: wrap;
        .image {width: 100%; height: 320px;
            .btn {opacity: 1;}
        }
        .comment {width: 100%; height: calc(100% - 322px);}
        app-post-comment-form {
            .mat-form-field-appearance-outline .mat-form-field-infix {padding: 0px;}
            textarea.mat-input-element {min-height: 55px;}
        }
    }
    @media(max-width: 574px) {
        .image {height: 220px;}
        .comment {height: calc(100% - 222px);}
    }
} // Popup image Formating

.edit-posts {padding: 0px !important;
    .new-post {margin-bottom: 0px; border: none;}
} // Edit post css end

// Loadings css start
@keyframes n { 0% {opacity:.3 } 50% {opacity:.7} to {opacity:.3} }
.load-1 {height: 100%; position: static; width: 100%; z-index: 1;
    .L1 { padding: 20px 15px 20px 10px; box-sizing: border-box; border-bottom: 1px solid rgba(0, 47, 52, 0.2); background: $white; display: flex;}
    .L1 .L3 {height: 24px; background-color: #dcdcdc; margin: 10px 5px 0; animation-duration: 1s; animation-fill-mode: forwards; animation-iteration-count: infinite; animation-name: n; animation-timing-function: linear; background: rgba(0, 47, 52, 0.2); background-size: 900px 104px;}
    .L1 .L3,
    .L1 .L2 {display: inline-block; position: relative;}
    .L1 .L2 {padding-right: 8px; width: 62px; vertical-align: top; float: left;}
    .L1 .L5 {width: calc(100% - 62px); display: inline-block; padding-left: 2%;}
    .L1 .L7 { padding: 5px 0; color: #002f34;}
    .L1 .L7,
    .L1 .ZnqJl {font-size: 12px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
    .L1 .ZnqJl {margin-top: 2px; color: rgba(0, 47, 52, 0.36);}
    .L1 .L1w {clear: both;}
    .L1 .L4 { width: 50px; height: 50px; border-radius: 50%; display: block;}
    .L1 .L6 {height: 16px; display: block;}
    .L1 .ntTy4 {height: 12px; display: block;}
    .L1 .L3w { height: 10px; display: block;}
    /*@media only screen and (max-width: 1023px) {
      display: block; position: relative; z-index: 1001; top: 0; bottom: 0; left: 0; right: 0; overflow: hidden; height: calc(100vh - 80px); background-color: $white;
    }*/
} // Loading css end

.input-group-search {position: relative;
    .form-control {border-color: #ddd; height: 45px; padding-left: 35px; padding-right: 25px;}
    .input-group-text {position: absolute; z-index: 10; background-color: transparent; border: none; left: -2px; top: 10px;}
    .close {position: absolute; z-index: 10; background-color: transparent; border: none; right: 8px; top: 12px; font-size: 20px;}
}


.friends-privacy {width: 100%; float: left; position: relative;
    .input-group {position: relative;
        .form-control {border-color: #ddd; height: 45px; padding-left: 35px; padding-right: 25px;}
        .input-group-text {position: absolute; z-index: 10; background-color: transparent; border: none; left: -2px; top: 10px;}
        .close {position: absolute; z-index: 10; background-color: transparent; border: none; right: 8px; top: 12px; font-size: 20px;}
    }
    .scroll-inner {margin-left: -8px; margin-right: -8px;}
    .mat-checkbox {width: 100%; float: left; padding: 5px 15px; cursor: default; border-top: 1px solid transparent; border-bottom: 1px solid transparent; transition: all 0.3s ease-in-out;
        &:hover {border-top-color: #ddd; border-bottom-color: #ddd; background-color: #eee; }
    }
    .mat-checkbox-layout {width: 100%; margin-bottom: 0px;}
    .mat-checkbox-label {width: calc(100% - 28px);}
    .profile {width: 100%;
        .image {width: 60px;}
        .caption {width: calc(100% - 60px); padding-left: 15px;}
        h5 {font-size: 15px; margin-bottom: 1px;}
    }
    .loadings {position: relative;}
    .all-list + .loadings, .all-list ~ .loadings {margin-top: -27px;}
} // Friend Privacy css end

.announcement {
    .text p:last-child {margin-bottom: 0px;}
} // Announcement css end

app-add-photo {
    .card.new-post {margin-bottom: 0px; border: none;
        .button {padding-top: 12px; position: relative;
            //&:before {content: ""; position: absolute; width: calc(100% + 30px); left: 0px; top: 0px; border-top: 1px solid $gray-300;}
        }
    }
}

.like-user-list {
    &.mat-dialog-content {padding: 0px; position: relative; min-height: 85vh; padding-top: 50px; overflow: hidden;}
    .close-button {position: absolute; right: 4px; top: -44px; z-index: 9;}
    .mat-tab-label{ min-width: 60px;}
    .model-inner {position: relative; width: 100%;}
    .mat-tab-header {position: absolute; left: 0px; top: -50px; width: 100%;}
    .mat-tab-body-wrapper {min-height: 76vh; overflow: initial;}
    .mat-tab-body {overflow: inherit !important;}
    .mat-tab-body-content {overflow: inherit;}
    .user-list {width: 100%; height: 100%; min-height: 76vh; max-height: 76vh; overflow: auto;}
} // Like User List css end

.announcement {
    .owl-prev {position: absolute; left: -4px; top: 50%; transform: translateY(-100%); opacity: 0; transition: all .6s ease-in-out;}
    .owl-next {position: absolute; right: -4px; top: 50%; transform: translateY(-100%); opacity: 0; transition: all .6s ease-in-out;}
    button {position: absolute; z-index: 2; right: 4px; top: -11px; opacity: 0; transition: all .6s ease-in-out;}
    &:hover {
        .owl-prev{opacity: 1;}
        .owl-next{opacity: 1;}
        button {opacity: 1;}
    }
} // Announcement css end

.css-scroll {scrollbar-color: $primary $gray-300; scrollbar-width: thin;
	&::-webkit-scrollbar {width: 8px; background-color: $gray-300;}
	&::-webkit-scrollbar-thumb {background-color: $primary; border-radius:10px;}
	&::-webkit-scrollbar-thumb:hover {background-color: $primary; border:1px solid $primary;}
	&::-webkit-scrollbar-thumb:active {background-color: $primary; border:1px solid $primary;}
}
ngx-stripe-card {width: 100%; float: left; border: 1px solid #ddd; padding: 9px 15px; border-radius: 4px;}
.title-count {
    .material-icons {font-family: 'Muli', sans-serif; font-size: 13px; padding-top: 4px; float: right; width: 38px;}
    &.www .material-icons {font-size: 18px; width: 58px; padding-top: 0px; color: #666;}
}

.paid-icon {position: relative;
    &:before {content: "";position: absolute;background-image: url(/assets/img/Paid.png);background-size: 30px 30px;display: inline-block;
        width: 30px;height: 30px;top: 0;background-repeat: no-repeat;z-index: 1;left: 0;}
}

.announcement .iframe iframe {pointer-events: none; width: 100%;}
app-announcement-video iframe {width: 100%; margin-bottom: -23px;}
.cdk-global-scrollblock {position: static;}
.timeline-backdrop {pointer-events: inherit;}

.cropper-dialog {
    button:focus {outline: none;}
    .mat-dialog-container {position: relative;}
    .dialog-close {position: absolute; top: 0px; right: 0px;}
    .mat-dialog-content {min-height: 200px; max-height: 80vh;}
    .uploder {text-align: center; padding: 10px;}
    .buttons {margin-left: -2px; margin-right: -2px;
        .btn {margin-left: 2px; margin-right: 2px; margin-bottom: 4px;}
    }
    // .profile-image .cropper {width: 200px !important; height: 200px !important;}
    // .cropper {
    //     //.resize {pointer-events: none !important;}
    //     //.resize-bar {pointer-events: none !important;}
    // }
}  // Image Cropper css end

.live-dialog {
    .close {position: absolute; top: 0px; right: 0px;}
    .mat-dialog-container {display: flex; align-items: center; justify-content: center;}
}

.invites {
    .mat-chip-list-wrapper {overflow-x: auto;}
    .mat-chip {font-size: 13px;}
}

.mat-hint {margin: 0 -10px;
    img {width: 30px; margin-right: 5px; vertical-align: text-bottom;}
}